import React, { useContext, useEffect, useRef, useState } from 'react';
import { format, subDays } from 'date-fns';
import { convertDateToUTC } from '../../../utils/dateUtils';
import { tableCellStyles } from '../TableCell/tableCellStyles';
import TableCell from '../TableCell/TableCell';
import { CalendarEvent } from '../../types/Calendar/ICalendarIndex';
import {
  chipBackgroundColorPicker,
  chipTextColorPicker,
} from '../../Chip/chipUtils/chipColorPickers';
import { ChipVariantEnum, IconEnum } from '../../Chip/chipUtils/ChipEnums';
import Chip from '../../Chip/Chip';
import { theme } from '../../../assets/siteLineTheme';
import { completionStatus } from '../../../utils/completionUtils';
import { eventItems } from '../../Forms/TaskUtils';
import { taskTableRowStyles } from './taskTableRowStyles';
import drawerPropsStore from '../../../stores/drawerPropsStore';
import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from '../../../utils/analytics';
import {
  getTaskDescriptionByTaskId,
  getTaskLevelDetails,
  getTaskStoreCompletionStatus,
  getTaskStoreLevelDetails,
  getTaskStoreNumberCompletion,
} from '../../../api/apiV1Task';
import { copyAttachmentsToNewGCPBucket } from '../../../api/apiFile';
import { fetchRegionalTaskCompletionStatus } from '../../../api/apiUtils';
import { DrawerContentType } from '../../../stores/models/DrawerProps';
import { groups } from '../../../context/constants';
import { v4 as uuidV4 } from 'uuid';
import appliedFiltersStore from '../../../stores/appliedFiltersStore';
import { AppContext } from '../../../context/AppContext';
import useFetchUserGroup from '../../../hooks/fetchUserGroup';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';
import Papa from 'papaparse';
import { downloadCsv } from '../../../utils/csvValidator';
import { IconButton } from '../../Buttons/IconButton';
import fireUseQueryStore from '../../../stores/fireUseQueryStore';

export type TableRowProps = {
  contentData: CalendarEvent | any;
};

const TaskTableRow: React.FC<TableRowProps> = (props) => {
  const { contentData } = props;
  const { appState } = useContext<any>(AppContext);
  const { userInfo } = appState;
  const userOktaGroup = useFetchUserGroup(userInfo);

  const [cellHeight, setCellHeight] = useState(0);
  const ref = useRef(null);

  const {
    id,
    title,
    task_type,
    end_date,
    store_scheduled_end_date,
    store_number_count,
    region_name,
  } = contentData;

  const { appliedFilters } = appliedFiltersStore(
    (state: { appliedFilters: any }) => ({
      appliedFilters: state.appliedFilters,
    })
  );

  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );

  const { setFireUseQuery } = fireUseQueryStore(
    (state: { setFireUseQuery: any }) => ({
      setFireUseQuery: state.setFireUseQuery,
    })
  );

  const display_end_date =
    store_scheduled_end_date !== undefined
      ? format(convertDateToUTC(store_scheduled_end_date), 'MM/dd/yyyy')
      : format(convertDateToUTC(end_date), 'MM/dd/yyyy');
  const taskCompletionStatus = completionStatus(contentData);
  const completionStatusColor =
    taskCompletionStatus === 'Completed'
      ? theme.colorPalette.completionStatus.completed
      : taskCompletionStatus === 'Overdue'
      ? theme.colorPalette.completionStatus.overdue
      : theme.colorPalette.plainBlack;

  const eventItem = eventItems.find((item) => item.label === task_type);

  const { drawerProps, setDrawerProps } = drawerPropsStore(
    (state: { drawerProps: any; setDrawerProps: any }) => ({
      drawerProps: state.drawerProps,
      setDrawerProps: state.setDrawerProps,
    })
  );

  const openEdit = () => {
    setDrawerProps({
      ...drawerProps,
      drawerContentProps: {
        ...drawerProps.drawerContentProps,
        formMode: 'edit',
      },
    });
  };

  const openView = (event: CalendarEvent, wasFiredFromCalendar?: boolean) => {
    setDrawerProps({
      ...drawerProps,
      drawerContentType: DrawerContentType.TaskForm,
      isDrawerOpen: true,
      drawerContentProps: {
        openEdit,
        formMode: 'view',
        viewEvent: event,
        duplicateTaskHandler,
      },
    });
  };
  const getNewAttachmentsIdAndCopyFilesInGCP = (viewEvent: CalendarEvent) => {
    const newViewEvent = { ...viewEvent, attachments_id: uuidV4() };
    const oldId = viewEvent.attachments_id;
    if (newViewEvent.attachment_information?.length !== 0) {
      copyAttachmentsToNewGCPBucket(
        oldId,
        newViewEvent.attachments_id,
        newViewEvent.attachment_information
      );
    }
    return newViewEvent;
  };

  const duplicateTaskHandler = () => {
    if (drawerProps.drawerContentProps.viewEvent) {
      const viewEventToSet = getNewAttachmentsIdAndCopyFilesInGCP(
        drawerProps.drawerContentProps.viewEvent
      ) as CalendarEvent;

      setDrawerProps({
        ...drawerProps,
        drawerContentProps: {
          ...drawerProps.drawerContentProps,
          formMode: 'create',
          viewEvent: viewEventToSet,
        },
      });
    }
  };

  /* istanbul ignore next */
  const findFullEventToOpen = async (
    selectedEvent: any,
    wasFiredFromCalendar?: boolean
  ) => {
    const { id, uc_event, end_date, title } = selectedEvent;
    /* istanbul ignore next */
    sendAnalyticsEvent({ ...analyticsEventConstants.VIEW_TASK, state: title });

    if (uc_event) {
      openView(
        { ...selectedEvent, end_date: subDays(new Date(end_date), 1) },
        wasFiredFromCalendar
      );
      return;
    }
    const { locationnumber } = userInfo;
    const isMichaelOrStanley =
      userOktaGroup === groups.MICHAEL || userOktaGroup === groups.STANLEY;
    const isDistroEvent = selectedEvent?.distro_file_information?.length;

    const storeLevelDetailsPromise =
      isMichaelOrStanley || appliedFilters.store_number
        ? getTaskStoreLevelDetails(
            id,
            appliedFilters.store_number || locationnumber
          )
        : !isDistroEvent
        ? getTaskStoreLevelDetails(id)
        : {};

    const taskLevelDetails = await getTaskLevelDetails(id);
    taskLevelDetails.distro_file_information =
      taskLevelDetails?.distro_file_information
        ? [taskLevelDetails?.distro_file_information]
        : [];

    let taskDescriptionByTaskId = await getTaskDescriptionByTaskId(id);
    taskDescriptionByTaskId = taskDescriptionByTaskId.ops
      ? { task_description: taskDescriptionByTaskId }
      : taskDescriptionByTaskId;
    const storeLevelDetails = await storeLevelDetailsPromise;
    const regionalTaskStoreCompletionStatus =
      await fetchRegionalTaskCompletionStatus(id);

    let eventObj = {
      ...selectedEvent,
      ...taskLevelDetails,
      ...taskDescriptionByTaskId,
      ...storeLevelDetails,
      ...regionalTaskStoreCompletionStatus,
    };

    const taskStoreCompletionStatus =
      appliedFilters.store_number &&
      appliedFilters.store_number !== 'All' &&
      eventObj.require_completion
        ? await getTaskStoreCompletionStatus(id, appliedFilters.store_number)
        : {};

    eventObj = {
      ...eventObj,
      ...taskStoreCompletionStatus,
    };
    openView({ ...eventObj }, wasFiredFromCalendar);
  };

  const { titleRow, completionStatusRow, mobileCompletionStatusRow } =
    taskTableRowStyles();

  useEffect(() => {
    // @ts-ignore
    setCellHeight(ref.current.clientHeight);
  });

  const getAllStoreCompletionList = async (task_id: string, title: string) => {
    let allStoreCompletionList;
    if (appliedFilters.district_number !== undefined) {
      allStoreCompletionList = await getTaskStoreNumberCompletion(
        task_id,
        parseInt(appliedFilters.district_number),
        'district'
      );
    } else if (appliedFilters.region_number !== undefined) {
      allStoreCompletionList = await getTaskStoreNumberCompletion(
          task_id,
          parseInt(appliedFilters.region_number),
          'region'
      );
    } else if (appliedFilters.territory_number !== undefined) {
      allStoreCompletionList = await getTaskStoreNumberCompletion(
        task_id,
        parseInt(appliedFilters.territory_number),
        'territory'
      );
    } else {
      allStoreCompletionList = await getTaskStoreNumberCompletion(
        task_id,
        undefined,
        undefined
      );
    }
    if (allStoreCompletionList !== undefined) {
      const csv = Papa.unparse(allStoreCompletionList);
      downloadCsv(csv, title);
      sendAnalyticsEvent(analyticsEventConstants.TASK_COMPLETION_STATUS_LIST);
    }
  };

  useEffect(() => {
    if (
      drawerProps.drawerContentType !== undefined &&
      drawerProps.drawerContentType === DrawerContentType.TaskForm &&
      !drawerProps.isDrawerOpen
    ) {
      setFireUseQuery({ triggerTime: new Date(Date.now()) });
    }
  }, [drawerProps.isDrawerOpen]);

  return (
    <div
      style={tableCellStyles({ showBorder: true }).rowContainer}
      ref={ref}
      onClick={() => findFullEventToOpen(contentData)}
    >
      <div
        style={
          tableCellStyles({ completionStatusColor, cellHeight })
            .taskCompletionStatusColorBar
        }
      />
      <TableCell styleProps={titleRow}>
        <div>{title}</div>
      </TableCell>
      <TableCell styleProps={{ cellWidth: '15%', justifyContent: 'center' }}>
        <div style={{ left: '-5px', position: 'relative' }}>
          <Chip
            chipBackgroundColor={chipBackgroundColorPicker(
              userPreference.colorMode,
              task_type,
              undefined
            )}
            chipTextColor={chipTextColorPicker(
              userPreference.colorMode,
              task_type,
              undefined
            )}
            variant={ChipVariantEnum.Filled}
          >
            {eventItem && eventItem.abbreviation}
          </Chip>
        </div>
      </TableCell>
      {window.innerWidth > 420 ? (
        <>
          {appliedFilters.store_number === undefined &&
            contentData.id !== undefined && (
              <TableCell
                styleProps={{ cellWidth: '15%', justifyContent: 'center' }}
              >
                {/* Region Name when location selected */}
                {(appliedFilters.district_number !== undefined ||
                  appliedFilters.territory_number !== undefined) && (
                  <div>
                    {region_name} ({store_number_count})
                  </div>
                )}
                {/* Multiple when no location filter active */}
                {appliedFilters.district_number === undefined &&
                  appliedFilters.territory_number === undefined && (
                    <div>Multiple ({store_number_count})</div>
                  )}
              </TableCell>
            )}
          {/* Marketing and Promotions tasks */}
          {contentData.id === undefined &&
            appliedFilters.store_number === undefined && (
              <TableCell
                styleProps={{ cellWidth: '15%', justifyContent: 'center' }}
              >
                <div>All</div>
              </TableCell>
            )}
          <TableCell
            styleProps={{ cellWidth: '20%', justifyContent: 'center' }}
          >
            <div style={{ color: completionStatusColor }}>
              {display_end_date}
            </div>
          </TableCell>
          <TableCell
            styleProps={{ cellWidth: '15%', justifyContent: 'center' }}
          >
            <div style={completionStatusRow}>
              {taskCompletionStatus}
              {(appliedFilters.store_number === undefined ||
                appliedFilters.store_number === '') &&
                (userOktaGroup === groups.JAN ||
                  userOktaGroup === groups.SUPER_GABE ||
                  userOktaGroup === groups.GABE) &&
                window.innerWidth > 420 && (
                  <IconButton
                    icon={IconEnum.Download}
                    onClick={() =>
                      getAllStoreCompletionList(id, title + '_completion')
                    }
                    label={'Download Completion List'}
                    margin={'0 0.2em'}
                    color={
                      theme.newColorPalette.zoidbergBlack[
                        userPreference.colorMode
                      ]
                    }
                  />
                )}
            </div>
          </TableCell>
        </>
      ) : (
        <>
          <TableCell
            styleProps={{
              cellWidth: '40%',
              justifyContent: 'center',
              isMobile: true,
            }}
          >
            <div
              style={{
                color: completionStatusColor,
                margin: '0px auto',
                width: 'fit-content',
              }}
            >
              {display_end_date}
            </div>
            <div style={mobileCompletionStatusRow}>{taskCompletionStatus}</div>
          </TableCell>
        </>
      )}
    </div>
  );
};

export default TaskTableRow;
